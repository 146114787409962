
import { defineComponent } from "vue";
import SubCatalogueGrid from "@/components/controls/catalogueGrid/SubCatalogueGrid.vue";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { requiredRule } from "@/components/controls/catalogueGrid/columns/baseValidationRules";
import dCatalogueItemCreateEditDialog from "./DCatalogueItemCreateEditDialog.vue";

export default defineComponent({
  components: {
    SubCatalogueGrid,
  },
  props: {
    parentModel: { required: true },
  },
  methods: {},
  computed: {
    dCatalogueItemCreateEditDialog(): any {
      return dCatalogueItemCreateEditDialog;
    },
  },
  data() {
    return {
      columns: [
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
          validationRules: [requiredRule],
        }),
        new TextColumnConfig({
          dataField: "data",
          caption: "Данные",
          validationRules: [requiredRule],
          allowFiltering: false,
          allowSorting: false,
          // Отображение data
          customDisplayCallback: (items: any) => {
            if (items)
              return JSON.parse(items)
                .map((x: any) => x.value)
                .join(", ");
            return "";
          },
        }),
      ],
      selectionConfig: new TableSelectionConfig({
        mode: "single",
        hoverStateEnabled: false,
      }),
      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        height: "400px",
        pagingMode: "scroll",
        heightScroll: "400px",
        horizontalScroll: false,
      }),
      editingConfig: new TableEditingConfig({
        allowUpdating: true,
        allowAdding: true,
        allowDeleting: true,
        confirmDelete: true,
        allowDataFill: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
      }),
    };
  },
});
